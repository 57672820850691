import Vue from 'vue'
import App from './App.vue'
import 'normalize.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router';
import './../node_modules/bulma/css/bulma.css';
import "./styles/main.scss"
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
Vue.config.productionTip = false
Vue.use(VueAxios, axios);
Vue.use(Viewer)
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
