<template>
  <div>
    <div class="box u-flex u-row-between">
      <div class="text-color">
        www.ontimesmart.com I Hangzhou Ontime l.T.Co..Ltd.
      </div>
      <div class="u-flex">
        <div class="link-ico">
          <img class="ico" src="../assets/web-Facebook.png" alt="" />
        </div>
        <div class="link-ico">
          <img class="ico" src="../assets/twt.png" alt="" />
        </div>
        <div class="link-ico">
          <img class="ico" src="../assets/pinterest.png" alt="" />
        </div>
        <div class="link-ico">
          <img class="ico" src="../assets/youtube.png" alt="" />
        </div>
        <div class="link-ico">
          <img class="ico" src="../assets/pinterest.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bottomNavigation",
  props: {
    msg: String,
  },
  data() {
    return {
      index: 0,
      navArr: [
        {
          name: "Ontime ESL",
          id: "OntimeESL",
          childList: ["a", "b", "c"],
        },
        {
          name: "Product",
          id: "Product",
          childList: ["a", "b", "c"],
        },
        {
          name: "About us",
          id: "AboutUs",
          childList: ["a", "b", "c"],
        },
      ],
    };
  },
  methods: {
    change(index) {
      this.index = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  background: #333333;
  border-radius: unset;
  height: 70px;
  padding: 0 359px;
}
.text-color {
  color: rgba(255, 255, 255, 0.7);
}
.link-ico {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #484848;
  margin-right: 14px;
  .ico {
    //   width: 100%;
    //   height: 100%;
    cursor: pointer;
  }
}
</style>