<template>
  <div id="app">
    <NavigAtion v-if="!$route.meta.aaa"></NavigAtion>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <bottomNavigation v-if="!$route.meta.aaa"></bottomNavigation>
  </div>
</template>

<script>
import NavigAtion from "@/components/NavigAtion.vue";
import bottomNavigation from "@/components/bottomNavigation.vue";
export default {
  name: "App",
  components: {
    NavigAtion,
    bottomNavigation,
  },
  mounted() {},
};
</script>

<style>
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
