<template>
	<div>
		<div class="bj-di-se" v-if="showExpo">
      <div class="bj u-flex-col">
        <div class="u-abso" style="width: 100%;z-index: 9;height: 780px;overflow: hidden;">
          <nav class="navBox u-flex u-row-between" :style="topNavBg">
            <div class="img-bor">
              <img @click="openWebsite" src="../assets/logo.png" alt="" />
            </div>
            <div class="u-flex">
              <div v-for="(item, i) in navArr" :key="i" @click="change(i, item.id)"
                :class="{ isDefault: true, activate: index == i }">
                <span> {{ item.name }}</span>
                <span class="brackets" :style="{'display':item.id=='Badge'?'none':''}">&lt;</span>
                <div class="nav-item-list" @click.stop>
                  <a href="javascript:void(0)" class="nav-item-list-item" v-for="(a, k) in item.childList" :key="k"
                    @click.stop="anchor1(k, i, item.id)">
                    {{ a }}
                  </a>
                </div>
              </div>
              <button @click="anchor1(6, 1, 'Product')" class="hollow-out-btn">
                <span style=""> CONTACT US</span>
              </button>
            </div>
          </nav>
          <div v-if="index != 1&&index!=2" class="u-flex-col u-col-center p-t-245" style="flex: 1;">
            <h6 style="color: #ffffff; text-align: center">
            </h6>
            <h1 class="fz-40" style="color: #ffffff; text-align: center; font-weight: bold">
              The most flexible ESL tag solution available in the market today
            </h1>
            <h1 class="fz-40" style="color: #ffffff; text-align: center; font-weight: bold">
              Ontime Electronic Shelf Labels
            </h1>
            <h1 class="fz-40" style="color: #ffffff; text-align: center; font-weight: bold">
              a solution that combines hard and software in one package
            </h1>
            <button @click="anchor1(0, 1, 'Product')" class="orange-btn m-t-55">
              <span>PRODUCT</span>
            </button>
          </div>
		  <div v-else-if="index==2" class="Badge">
		    <div class="work_bj u-flex-col u-col-center p-t-245">
				<h1 class="fz-40" style="color: #ffffff; text-align: center; font-weight: bold;font-size: 60px;">
				  Digital Name Badge
				</h1>
				<div class="work_text">An Amazing Solution of Badges</div>
			</div>
		  </div>
          <div v-else class="videoContainer">
            <video class="fullscreenVideo" preload="auto" id="bgVid" ref="vueMiniPlayer" playsinline="" autoplay="" loop
              muted="">
              <source src="../assets/bj.mp4" type="video/mp4">
            </video>
            <div class="fz-40"
              style="color: #ec2c64; text-align: center; font-weight: bold; position: absolute;left: 0;bottom: 10px;width: 100%;text-align: center;">
              New Series Launch Soon
            </div>
          </div>
        </div>

      </div>
    </div>
		<div class="bj-di-ses" v-else>
			<div class="bj u-flex-col">
				<div class="u-abso" style="width: 100%;z-index: 9;height: 680px;overflow: hidden;">
					<nav class="navBoxs u-flex u-row-between" :style="topNavBg">
						<div class="img-bor">
							<img @click="openWebsite" src="../assets/logo.png" alt="" />
						</div>
						<div class="u-flex">
							<div v-for="(item, i) in navArr" :key="i" @click="change(i, item.id)"
								:class="{ isDefault: true, activates: index == i }">
								<span> {{ item.name }}</span>
								<span class="brackets" :style="{'display':item.id=='Badge'?'none':''}">&lt;</span>
								<div class="nav-item-list" @click.stop>
									<a href="javascript:void(0)" class="nav-item-list-items"
										v-for="(a, k) in item.childList" :key="k" @click.stop="anchor1(k, i, item.id)">
										{{ a }}
									</a>
								</div>
							</div>
							<button @click="anchor1(6, 1, 'Product')" class="hollow-out-btns" >
								<span style=""> CONTACT US</span>
							</button>
						</div>
					</nav>
					<div class="u-flex-col u-col-center p-t-245" style="flex: 1;">
						<h1 class="fz-40"
							style="color: #ffffff; text-align: center; font-weight: bold;font-size: 72px;">
							Smart Retail EAS Solution
						</h1>
					</div>
				</div>
			</div>
		</div>
		<div id="goTop" v-show="goTopShow">
			<a @click="goTop" class="toTop">Top</a>
		</div>
	</div>
</template>

<script>
	import "vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css";
	// import { swiper, swiperSlide } from 'vue-awesome-swiper'
	export default {
		name: "NavigAtion",
		props: {
			msg: String,
		},
		components: {
			// swiper,
			// swiperSlide
		},
		data() {
			return {
				showExpo:true,
				swiperOptionTop: {
					loop: true,
					fullscreenVideo: {},
					ppp: '',
					autoplay: {
						delay: 8000,
					},
				},
				index: 0,
				navArr: [
					// {
					// 	name: "Ontime Expo 2023",
					// 	id: "Expo",
					// 	childList: ["Mitag ", "RFID+EAS Source Tagging", "Self-service Checkout ", "Antenna-Less",
					// 		"Indoor Positioning System"
					// 	],
					// },
					{
						name: "Ontime ESL",
						id: "Home",
						childList: ["Benefits", "System", "Application"],
					},
					{
						name: "Product",
						id: "Product",
						childList: ["A1 Labels", "S1 Labels", "The Multicolor Labels", "Access point & PDA",
							"Accessory", "Platform", "Contact us",
						],
					},
					{
						name: "Badge",
						id: "Badge",
					},
					{
						name: "About us",
						id: "AboutUs",
						childList: [
							"Company Profile",
							"Business Unit",
							"Our partners",
						],
					},
				],

				scrollTop: "",
				goTopShow: false,
				topNavBg: {
					backgroundColor: "",
				},
			};
		},
		computed: {
			routeName() {
				console.log(this.$route.name);
				return this.$route;
			},
		},
		watch: {
			$route(val) {
				// console.log(val);
				document.title = val.name;
				switch (this.$route.name) {
					// case "Expo":
					// 	this.index = 0;
					// 	break;
					case "Home":
						this.index = 0;
						break;
					case "Product":
						this.index = 1;
						break;
					case "Badge":
						this.index = 2;
						break;
					case "AboutUs":
						this.index = 3;
						break;
					default:
						this.index = 0;
				}
				this.bo()
			},
			fullscreenVideo: {
				handler(val) {
					//逻辑处理
					console.log(11111, val)
				},
				deep: true
			}
		},
		mounted() {
			window.addEventListener("scroll", this.handleScroll);
// console.log(this.showExpo)
		},
		destroyed() {
			window.removeEventListener("scroll", this.handleScroll);
			// clearInterval(this.play)
		},
		methods: {
			bo() {
				if (this.index == 2) {
					this.$nextTick(() => {
						this.fullscreenVideo = this.$refs.vueMiniPlayer
						// console.log(this.fullscreenVideo.played)
						// this.fullscreenVideo.play();//播放
						// this.play()
						// console.log(this.$refs.vueMiniPlayer.duration)
						// this.ppp = setInterval(()=>{
						//   this.play()
						// },14500)
					})
				}
			},
			play() {
				setTimeout(() => {
					this.fullscreenVideo.pause(); //暂停
					console.log(this.fullscreenVideo.duration, 2222222)
					setTimeout(() => {
						this.fullscreenVideo.play(); //暂停
					}, 3500)
				}, 11000)

			},
			openWebsite() {
				window.location.href = "http://esl.ontimesmart.com/";
			},
			handleScroll() {
				this.scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				if (this.scrollTop) {
					if (this.scrollTop > 150) {
						//设置页面滑动多少显示按钮
						this.goTopShow = true;
					} else {
						this.goTopShow = false;
					}
					this.topNavBg.backgroundColor = `rgba(88, 83, 81,
	        	${this.scrollTop / (this.scrollTop + 60)})`; // scrollTop + 多少根据自己的需求设置
				} else if (this.scrollTop === 0) {
					this.topNavBg.backgroundColor = "transparent"; // 设置回到顶部时，背景颜色为透明
				}
			},
			goTop() {
				let timer = null,
					_that = this;
				cancelAnimationFrame(timer);
				timer = requestAnimationFrame(function fn() {
					if (_that.scrollTop > 0) {
						_that.scrollTop -= 200; //回到顶部的速度
						document.body.scrollTop = document.documentElement.scrollTop =
							_that.scrollTop;
						timer = requestAnimationFrame(fn);
					} else {
						cancelAnimationFrame(timer);
						_that.goTopShow = false;
					}
				});
			},
			anchor1(k, index, id) {
				console.log(k, index, id)
				if(id=='Expo'){
					this.showExpo=false
					setTimeout(() => {
						let idTop = document.querySelector(`#${id}-${k + 1}`).offsetTop;
						console.log(idTop,"	console.log(idTop)123")
						document.documentElement.scrollTop = idTop-100;
					}, 100);
				}else{
					this.showExpo=true
					setTimeout(() => {
						let idTop = document.querySelector(`#${id}-${k + 1}`).offsetTop;
						console.log(idTop,"console.log(idTop)")
						document.documentElement.scrollTop = idTop;
					}, 100);
				}
				if (this.index != index) {
					this.index = index;
					this.$router.push({
						name: id
					});
				}
				
			},
			change(index, id) {
				console.log(index,id);
				console.log(this.index)
				if (this.index != index) {
					this.index = index;
					this.$router.push({
						name: id
					});
				}
				if(id=='Expo'){
					this.showExpo=false
				}else{
					this.showExpo=true
				}
				console.log(this.showExpo)
			},
			anchor(k, index, id) {
				console.log(k, index, id);
				if (this.index != index) {
					this.index = index;
					this.$router.push({
						name: id
					});
					setTimeout(() => {
						document.querySelector(`#${id}-${k + 1}`).scrollIntoView({
							behavior: "smooth",
							block: "center",
							inline: "nearest",
						});
					}, 100);
				} else {
					document.querySelector(`#${id}-${k + 1}`).scrollIntoView({
						behavior: "smooth",
						block: "center",
						inline: "nearest",
					});
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	#bgVid {
		width: 100%;
		overflow: hidden;
		transform: translateY(-7%);
	}

	// .videoContainer:before {
	//   content: "";
	//   position: absolute;
	//   width: 100%;
	//   height: 100%;
	//   display: block;
	//   z-index: -1;
	//   top: 0;
	//   left: 0;
	//   background: rgba(25, 29, 34, .65);
	// }

	.navBox,
	.navBoxs {
		border-bottom: 1px solid rgba(255, 255, 255, 0.4);
		padding: 15px 361px 15px 361px;
		width: 100%;
		z-index: 999;
		position: fixed;

		// box-shadow: 0px 0px 36px 10px rgba(0, 0, 0, 0.09);
		.isDefault,
		.isDefaults {
			font-size: 18px;
			color: #ffffff;
			position: relative;
			margin-right: 40px;
			min-width: 100px;
			white-space: nowrap;
			display: flex;
			font-weight: bold;
			justify-content: center;
			// text-align: center;
		}

		.isDefaults {
			width: 303px;
			margin-right: 0px;
			min-width: 303px;
		}


		.brackets {
			display: block;
			margin-left: 10px;
			margin-top: 1px;
			transition: 0.3s;
			transform: scale(1.5, 1) rotate(-90deg);
		}

		.isDefault:hover,
		.isDefaults:hover {
			cursor: pointer;

			.nav-item-list {
				// display: block;
				max-height: 350px;
			}

			.brackets {
				transform: scale(1.5, 1) rotate(90deg);
			}
		}

		.activate,.activates {
			color: #f75757;
			font-weight: bold;
		}

		.activates {
			color: #0076C2;
			// width: 303px;
			// height: 100px;
			// background: #0076C2;
			// display: flex;
			// align-items: center;
			// text-align: center;
		}

		.nav-item-list {
			// display: none;
			max-height: 0;
			min-width: 100px;
			transition: all 0.5s;
			overflow: hidden;
			position: absolute;
			top: calc(100% + 10px);
			background: rgb(88, 83, 81);
			font-weight: initial;
			// width: 100%;
			text-align: left;

			a {
				padding: 10px;
				display: block;
				color: #ffffff;
				white-space: nowrap;
				font-size: 16px;
			}

			.nav-item-list-item:hover {
				background: rgb(247, 87, 87);
				// color: #003566;
			}
			.nav-item-list-items:hover {
				background: rgb(0,118, 194);
				// color: #003566;
			}
		}
	}

	.navBoxs {
		padding: 0 361px 0 361px;
		height: 100px;
	}

	.hollow-out-btn ,.hollow-out-btns{
		width: 160px;
		height: 40px;
		background: rgba(0, 0, 0, 0);
		border: 2px solid #f75757;
		border-radius: 20px;
		color: #ffffff;
		cursor: pointer;

		span {
			display: block;
			// transform: translateY(-1px);
		}

		&:hover {
			background: rgb(247, 87, 87);
		}
	}
	.hollow-out-btns{
		border: 2px solid #0076C2;
		&:hover {
			background:rgb(0,118, 194);
		}
	}

	.orange-btn {
		width: 200px;
		height: 50px;
		background: #f75757;
		border: none;
		border-radius: 25px;
		color: #ffffff;
		cursor: pointer;
		font-size: 26px;
		font-weight: bold;

		span {
			display: block;
			transform: translateY(-1px);
		}
	}

	.img-bor {
		//   border: 1px dashed rgba(255, 255, 255, 0.438);
		display: inline-block;
		width: 76px;
		height: 49px;
		cursor: pointer;
	}

	.bj-di-se {
		background: url(../assets/supermarket.png) no-repeat bottom / 100% 120%;
		height: 780px;
	}

	.bj-di-ses {
		background: url(../assets/expoBg.jpg) no-repeat bottom / 100% 120%;
		height: 680px;
	}

	.bj {
		background-color: rgba(0, 0, 0, 0.55);
		height: 100%;
	}

	.toTop:hover {
		background: rgba(247, 87, 87, 1);
		color: #fff;
	}

	.toTop {
		display: inline-block;
		border-radius: 50%;
		background: #fff;
		opacity: 0.8;
		position: fixed;
		display: flex;
		justify-content: center;
		align-items: center;
		bottom: 40px;
		right: 10px;
		color: #f75757;
		font-size: 20px;
		cursor: pointer;
		z-index: 999;
		box-shadow: 0 0 6px;
		padding: 20px;
	}
	.Badge{
		width: 100%;
		height: 780px;
		background: url(../assets/workcard.png) no-repeat bottom / 100% 120%;
	}
	.work_bj{
		background-color: rgba(0, 0, 0, 0.55);
		height: 100%;
	}
	.work_text{
		font-size: 30px;
		font-family: SourceHanSansSC, SourceHanSansSC;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 44px;
		margin-top: 10px;
	}
</style>
